.footer {
    height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #7096D1;
    color: var(--color-light);
}

.footer-text{
    margin-top:10px;
}

.icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
}