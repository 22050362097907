@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Karla:wght@400;700&family=Neucha&display=swap');
@import 'semantic-ui-css/semantic.min.css';

:root {
  --color-background: #F7F2EB;
  --color-foreground: #081F5C;
  --shadow-color: rgba(0, 0, 0, 0.2)
}

body.dark {
  --color-background: #081F5C;
  --color-foreground: #F7F2EB;
  --shadow-color: #F7F2EB;
}

.App {
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background);
  color: var(--color-foreground);
  font-family: "Neucha", cursive;
}
html {
  --line-color: #e5e7eb;
}


@media (prefers-color-scheme: dark) {
  body {
    background-color: var(--color-foreground);
    color: var(--color-background);
  }
}

