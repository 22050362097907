.content {
    display: flex;
    min-height: 85vh;

    /* flex-direction: row;
    align-items: center; */
}

.outlet {
    position: relative;
    /* width: 90%; */


}

.sidebar {
    width: 15%;
}