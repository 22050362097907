@import '~animate.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Karla:wght@400;700&family=Neucha&display=swap');



.about_container {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
}

.introduction {
    display: flex;
    flex-grow: 6;

}

.greeting {

    font-weight: bolder;
    font-family: "Neucha", cursive !important;
    font-size: 2rem;
}

.description {
    width: 60%;
    /* border:1px solid blue; */
    margin: auto;
    font-size: large;
    letter-spacing: 0.1em;
    padding: 2em;
    z-index: 1;

}

.photo {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skills {
    flex-grow: 4;
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    flex-basis: 40%;
    overflow: auto;
}

body.dark .skills {
    box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.1), 0 6px 20px 0 rgba(255, 255, 255, 0.1);
}

.photo img {
    width: auto;
    height: auto;
    max-width: 400px;
    max-height: 400px;
    min-height: 300px;
    min-width: 300px;
    border-radius: 50%;
    padding-top: 1rem;
}

.skill-icon {
    margin: 20px;
    font-size: 27px;
    transition: all 0.3s ease;
}

.skill-icon:hover {
    font-size: 48px;
    transition: all 0.2s ease;
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .skills {
        margin-top: 24px;
        font-size: smaller;
    }
}

@media (max-width: 768px) {
    .introduction {
        flex-direction: column;
        align-items: center;
    }

    .photo,
    .description {
        width: 100%;
        order: 1;
        padding: 8px;
    }

    .photo {
        order: 0;
    }

    .photo img {
        max-width: 200px;
        max-height: 300px;
        min-height: 100px;
        min-width: 100px;
    }
}

@media only screen and (max-width: 320px) {
    .introduction {
        font-size: 50%;
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 0;
    }

    .description,
    .greeting {
        font-size: 10px;

        margin: 0;
        padding: 0;
    }

    .photo img {
        max-width: 50px;
        max-height: 50px;
    }
}

@media (max-width: 360px) {
    .introduction {
        font-size: 2px;
    }
}