@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Karla:wght@400;700&family=Neucha&display=swap');

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    height: 100%;
    padding: 5px;
    padding-top: 50%;
    padding-bottom: 50%;
    
}


.container h3 {
    font-size: 15px;
    margin: 0;
    display: flex;
    align-items: center;
    font-family: "Neucha", cursive !important;
    font-weight: bold; 

}

.container h3 svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.icon-text-wrapper {
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
}

.active .icon-text-wrapper {
    background-color: #eddcd2;
    border-radius: 20px;
    padding: 7px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0.1, 0.6);
}

@media (max-width: 768px) {
    .container h3 span {
        display: none;
    }
}